$buttonGroupErrorWrapperGroupBorder: var(--buttonGroupErrorWrapperGroupBorder, 1px solid #fb6340); 
$buttonGroupErrorWrapperGroupBorderRadius: var(--buttonGroupErrorWrapperGroupBorderRadius, 4px); 

$buttonGroupTitleColor: var(--buttonGroupTitleColor, #6b6b6b); 
$buttonGroupTitleFontSize: var(--buttonGroupTitleFontSize, 14px); 
$buttonGroupTitleMargin: var(--buttonGroupTitleMargin, 0px 0px 8px 0px); 

$buttonGroupErrorColor: var(--buttonGroupErrorColor, #fb6340); 
$buttonGroupErrorFontSize: var(--buttonGroupErrorFontSize, 12px); 
$buttonGroupErrorLineHeight: var(--buttonGroupErrorLineHeight, 15px); 
$buttonGroupErrorOpacity: var(--buttonGroupErrorOpacity, 0); 
$buttonGroupErrorHeight: var(--buttonGroupErrorHeight, 0px); 

$buttonGroupErrorShowOpacity: var(--buttonGroupErrorShowOpacity, 1); 
$buttonGroupErrorShowMargin: var(--buttonGroupErrorShowMargin, 5px 0px 0px 0px); 
$buttonGroupErrorShowPadding: var(--buttonGroupErrorShowPadding, 0px 0px 0px 3px); 

$buttonGroupFlexWrap: var(--buttonGroupFlexWrap, nowrap);
$buttonGroupGap: var(--buttonGroupGap, 0px);