$formControlTitleDescMargin: var(--formControlTitleDescMargin, 4px);

$formTitleColor: var(--formTitleColor, #101828);
$formTitleFontWeight: var(--formTitleFontWeight, 600);
$formTitleFontSize: var(--formTitleFontSize, 18px);
$formTitleLineHeight: var(--formTitleLineHeight, 28px);

$formDescColor: var(--formDescColor, #475467);
$formDescFontWeight: var(--formDescFontWeight, 400);
$formDescFontSize: var(--formDescFontSize, 14px);
$formDescLineHeight: var(--formDescLineHeight, 20px);