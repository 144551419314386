$calendarLabelFontSize: var(--calendarLabelFontSize, 14px); 
$calendarLabelFontWeight: var(--calendarLabelFontWeight, 400); 
$calendarLabelColor: var(--calendarLabelColor, #6b6b6b); 
$calendarBtnMargin: var(--calendarBtnMargin, 0 0 0 0px); 
$calendarBtnBorderColor: var(--calendarBtnBorderColor, #6366f1); 
$calendarBtnColor: var(--calendarBtnColor, #6b6b6b); 
$calendarBtnHoverColor: var(--calendarBtnHoverColor, #e7e7e7); 
$calendarActionsBorderTop: var(--calendarActionsBorderTop, solid 1px #eff2f7); 
$calendarActionsPadding: var(--calendarActionsPadding, 10px 10px 0 0); 
$calendarActionsFontSize: var(--calendarActionsFontSize, 14px);
$calendarErrorBorder: var(--calendarErrorBorder, 1px solid #fb6340)