@import './icon.default.scss';

.iconOnClick {
	cursor: pointer;

	&.radius {
		border-radius: $iconBorderRadius;
	}

	&:hover {
		background-color: $iconBackgroundColor;
		border-radius: $iconBorderRadius;
	}
}
