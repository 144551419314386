$networkErrorModalWrapperPadding: var(--networkErrorModalWrapperPadding, 20px); 

$networkErrorModalTitleFontSize: var(--networkErrorModalTitleFontSize, 16px); 
$networkErrorModalTitleFontWeight: var(--networkErrorModalTitleFontWeight, bold); 
$networkErrorModalTitleMargin: var(--networkErrorModalTitleMargin, 0px 0px 10px 0px); 

$networkErrorModalSubtitleFontSize: var(--networkErrorModalSubtitleFontSize, 14px); 
$networkErrorModalSubtitleFontWeight: var(--networkErrorModalSubtitleFontWeight, 500); 
$networkErrorModalSubtitleMargin: var(--networkErrorModalSubtitleMargin, 0px 0px 10px 0px); 

$networkErrorModalListFontSize: var(--networkErrorModalListFontSize, 13px); 
$networkErrorModalListStyle: var(--networkErrorModalListStyle, circle); 