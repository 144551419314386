@import './form-control.default.scss';

.formControl {
    .formTitle {
        +.formDesc {
            margin-top: $formControlTitleDescMargin;
        }
    }
}

.formTitle {
    color: $formTitleColor;
    font-weight: $formTitleFontWeight;
    font-size: $formTitleFontSize;
    line-height: $formTitleLineHeight;
}

.formDesc {
    color: $formDescColor;
    font-weight: $formDescFontWeight;
    font-size: $formDescFontSize;
    line-height: $formDescLineHeight;
}