:root {
	--tableTableThBackgroundColor: #f9fafb;
	--tableTableTrBg: #f9f9f9;
	--tableTableTrHeight: 72px;
	--tableLinkColor: #5552ee; //TODO change to primary color
	--tableLinkFontWeight: 500;
	--tableBodyTextColor: #475467;
	--tableBodyTextFontWeight: 500;
	--tableTableThFontWeight: 500;
	--tableTableThBorderBottom: 1px solid #eaecf0;
	--tableTableThBorderRight: none;
	--tableTableTdBorderRight: none;
	--tableTableTdBorderBottom: 1px solid #eaecf0;
	--tableTableWrapperBorder: 1px solid #eaecf0;
	--tableSummaryFromTop: 42px;
}
