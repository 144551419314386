$tocTitleColor: var(--tocTitleColor, #1d1d1d);
$tocItemFontSize: var(--tocItemFontSize, 14px);
$tocItemFontWeight: var(--tocItemFontWeight, 500);
$tocItemMargin: var(--tocItemMargin, 0px 0px 5px 0px);
$tocItemBorderRadius: var(--tocItemBorderRadius, 0px 10px 10px 0px);
$tocItemTitleColor: var(--tocItemTitleColor, #1d1d1d);
$tocItemTitleHoverBackgroundColor: var(--tocItemTitleHoverBackgroundColor, #e6e6e6);

$tocActiveRouteBackgroundColor: var(--tocActiveRouteBackgroundColor, #e2e2ff80);
$tocActiveRouteColor: var(--tocActiveRouteColor, #6366f1);
$tocLinkPadding: var(--tocLinkPadding, 5px 5px 5px 30px);
$tocLinkActiveColor: var(--tocLinkActiveColor, #6366f1);
$tocLinkActiveFontWeight: var(--tocLinkActiveFontWeight, 600);
$tocLinkActiveBeforeMargin: var(--tocLinkActiveBeforeMargin, 0px 0px 0px -15px);
$tocLinkActiveBeforeColor: var(--tocLinkActiveBeforeColor, #6366f1);
$tocLinkActiveBeforeContent: var(--tocLinkActiveBeforeContent, '• ');