$rotateIconWidth: var(--rotateIconWidth, 20px);
$rotateIconHeight: var(--rotateIconHeight, 20px);
$iconToTheLeftPaddingRight: var(--iconToTheLeftPaddingRight, 3px);
$iconToTheRightPaddingLeft: var(--iconToTheRightPaddingLeft, 3px);
$disabledOpacity: var(--disabledOpacity, 0.5);

$textButtonFontSize: var(--textButtonFontSize, 14px);
$textButtonFontWeight: var(--textButtonFontWeight, normal);
$textButtonLineHeight: var(--textButtonLineHeight, normal);

$primaryTextButtonColor: var(--primaryTextButtonColor, #6366f1);
$primaryTextButtonHeight: var(--primaryTextButtonHeight, 100%);
$primaryTextButtonWidth: var(--primaryTextButtonWidth, 100%);

$secondaryTextButtonColor: var(--secondaryTextButtonColor, #6b6b6b);
$secondaryTextButtonHeight: var(--secondaryTextButtonHeight, 100%);
$secondaryTextButtonWidth: var(--secondaryTextButtonWidth, 100%);
$secondayTextHoverBackgroundColor: var(--secondayTextHoverBackgroundColor, #F3F4F6);

$secondaryButtonHeight: var(--secondaryButtonHeight, 40px);
$secondaryButtonFontSize: var(--secondaryButtonFontSize, 14px);
$secondaryButtonFontWeight: var(--secondaryButtonFontWeight, normal);
$secondaryButtonBackgroundColor: var(--secondaryButtonBackgroundColor, transparent);
$secondaryButtonPadding: var(--secondaryButtonPadding, 6px 10px);
$secondaryButtonBorderRadius: var(--secondaryButtonBorderRadius, 4px);
$secondaryButtonBorder: var(--secondaryButtonBorder, 1px solid #9ca3af);
$secondaryButtonColor: var(--secondaryButtonColor, #9ca3af);
$secondaryButtonHoverBackgroundColor: var(--secondaryButtonHoverBackgroundColor, #9ca3af);
$secondaryButtonHoverColor: var(--secondaryButtonHoverColor, #ffffff);
$secondaryButtonLineHeight: var(--secondaryButtonLineHeight, 20px);

$primaryButtonHeight: var(--primaryButtonHeight, 40px);
$primaryButtonFontSize: var(--primaryButtonFontSize, 14px);
$primaryButtonFontWeight: var(--primaryButtonFontWeight, normal);
$primaryButtonBackgroundColor: var(--primaryButtonBackgroundColor, #6366f1); // #7F56D9
$primaryButtonPadding: var(--primaryButtonPadding, 6px 10px);
$primaryButtonBorderRadius: var(--primaryButtonBorderRadius, 4px);
$primaryButtonBorder: var(--primaryButtonBorder, 1px solid #6366f1);
$primaryButtonColor: var(--primaryButtonColor, #ffffff);
$primaryButtonHoverBackgroundColor: var(--primaryButtonHoverBackgroundColor, #4f46e5); //#6941C6
$primaryButtonHoverColor: var(--primaryButtonHoverColor, #ffffff);
$primaryButtonLineHeight: var(--primaryButtonLineHeight, 20px);