@import './calendar.default.scss';

.error {
	border: $calendarErrorBorder;
}

.label {
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #344054;
}

.btn {
	height: 44px;
	margin: $calendarBtnMargin;
	color: $calendarBtnColor;
	display: flex;
	justify-content: left;

	&:hover {
		background-color: $calendarBtnHoverColor;
	}
}

.actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: $calendarActionsFontSize;

	.cancelBtn {
		width: 72px;
		height: 40px;
		padding: 10px 16px;
	}

	.applyBtn {
		width: 72px;
		height: 40px;
		border-radius: 8px;
	}
}