@import './avatar-details.default.scss';

.avatar {
	display: flex;
	align-items: center;

	> img {
		width: $avatarImgWidth;
		height: $avatarImgHeight;
	}

	.info {
		text-align: left;
		padding: $avatarTitleInfoPadding;

		.title {
			font-size: $avatarTitleFontSize;
			margin: $avatarTitleMargin;
			padding: $avatarTitlePadding;
			color: $avatarTitleColor;
		}

		.subtitle {
			font-size: $avatarSubtitleFontSize;
			color: $avatarSubtitleColor;
		}
	}
}
