@import 'src/styles/theme';
@import '@monorepo/base/src/styles/text/overflow';

$navWidth: 220px;

.nestedNavPage {
	width: $navWidth;
	z-index: 5;

	.nestedNav {
		width: 100%;
		height: 100%;
		min-width: $navWidth;
		max-width: $navWidth;
		margin: 50px 0px 0px 0px !important;
		padding-top: 20px;
		border-right: 1px solid #e7eaf0;

		.navTitle {
			font-weight: 500;
			font-size: 15px;
			line-height: 24px;
			padding: 0px 5px 10px 18px;
			max-width: $navWidth;
			min-width: $navWidth;
			width: $navWidth;
			@include text-overflow;
		}

		.navItem {
			margin: 3px 0px;

			.navLink {
				font-size: 12.5px;
				letter-spacing: 0.1px;
				font-weight: 500;
				color: $gray900;
				display: flex;
				align-items: center;
				gap: 4px;
				cursor: pointer;
				padding: 9px 0px 9px 25px;
				height: 100%;
				width: 100%;
			}

			&:hover {
				background-color: $hoverLinksBg;
			}
		}

		.nestedActiveNav {
			background-color: $hoverLinksBg;
			color: $primary700;

			.navLink {
				color: $primary700;
			}
		}

		.nestedNavGroup {
			.navGroupAccordionWrapper {
				.navGroupToggler {
					margin: 12px 20px 0px 20px;
					padding: 20px 0px 0px 0px;
					pointer-events: none;
					border-top: 1px solid #e7eaf0;

					.navGroupAccordionTitle {
						padding: 0px 0px 5px 0px;
						font-size: 10px;
						color: $gray700;
						font-weight: 500;
						letter-spacing: 0.3px;
					}

					.togglerIcon {
						display: none;
					}
				}
			}
		}
	}
}
