@import './sidebar.default.scss';

.button {
	display: flex;
	width: 18px;
	align-items: flex-start;
	flex-direction: column;

	&.lock {
		> div {
			&:first-child,
			&:nth-child(3) {
				width: 65%;
			}
		}
	}

	> div {
		transition: width 0.3s ease;
		width: 100%;
		background-color: $sidebarBurger;
		height: 2px;
		margin-bottom: 3px;
	}
}
