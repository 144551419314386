@import './page.default.scss';

.article {
	display: flex;
	padding: $pageArticlePadding;
	flex-direction: column;
	width: 100%;

	&.rowDirection {
		flex-direction: row;
	}
}

.divider {
	position: relative;
	top: 200px;
	top: -825px;
}
