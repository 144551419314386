@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	display: flex;
	width: 100%;

	&.sticky {
		height: 100vh;
		overflow: hidden;
	}
	.outletWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// margin-bottom: 32px;
		padding: 100px 0px 100px 0px;
		// height: 100%;
		position: relative;

		.manifestoLogo {
			position: absolute;
			left: 32px;
			top: 32px;
		}

		.card {
			display: flex;
			flex-direction: column;
			gap: 40px;
			width: 360px;
			height: 100%;
			position: relative;
		}

		.credits {
			color: #475467;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			left: 32px;
			bottom: 25px;
			position: absolute;
		}
	}

	.manifestoExample {
		width: 100%;

		@media (max-width: $largeTablet) {
			display: none;
		}

		background: linear-gradient(45deg, #42307d 0%, #7f56d9 100%);
		position: relative;
		min-height: 100vh;

		.leftPattern {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.rightPattern {
			position: absolute;
			right: 0;
			top: 0;
		}

		.demoImage {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
}

@media (max-width: $largerDesktop) {
	.wrapper {
		.siginSignup {
			.manifestoLogo {
			}

			.card {
				position: relative;
				left: 20px;
			}

			.credits {
			}
		}

		.manifestoExample {
			.leftPattern {
			}

			.rightPattern {
			}

			.demoImage {
			}
		}
	}
}
