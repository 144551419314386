@import './tooltip.default.scss';

.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	.fullWidth {
		width: 100%;
	}

	.tooltip {
		position: absolute;
		bottom: $tooltipFromBottom;
		width: max-content;
		max-width: $tooltipMaxWidth;
		padding: $tooltipPadding;
		z-index: 8;
		border-radius: $tooltipBorderRadius;
		transition: all 0.15s ease-in-out;
		left: $tooltipFromLeft;
		transform-origin: 50% 75%;
		transform: translate(-50%) scale(0.8);
		visibility: hidden;
		opacity: 0;

		text-align: center;
		line-height: $tooltipLineHeight;
		font-weight: $tooltipFontWeight;
		font-size: $tooltipFontSize;
		word-break: break-word;

		&:after {
			content: '';
			position: absolute;
			top: $tooltipArrowAfterFromTop;
			margin: $tooltipArrowAfterMargin;
			border: $tooltipArrowAfterBorder;
			left: $tooltipArrowAfterFromLeft;
		}

		&.alignToRight {
			right: $tooltipAlignToRightFromRight;
			left: auto !important;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: auto !important;
				right: $tooltipAlignToRightAfterFromRight;
			}

			&.active {
				transform: unset !important;
			}
		}

		&.alignToLeft {
			left: $tooltipAlignToLeftFromLeft;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: $tooltipAlignToLeftAfterFromLeft;
			}

			&.active {
				transform: unset !important;
			}
		}

		&.normalAlign {}

		&.dirFromLeft {
			bottom: -50%;
			left: 100%;

			&::after {
				left: -5px;
				top: 50%;
				transform: translateY(-50%);
				border-color: transparent #414141 transparent transparent !important;
			}
		}

		&.active {
			visibility: visible;
			opacity: 1;
			transform: translate(-50%) scale(1);
		}

		&.light {
			background-color: $tooltipLightBackgroundColor;
			color: $tooltipLightColor;
			box-shadow: $tooltipLightBoxShadow;

			&::after {
				border-color: $tooltipLightArrowAfterBorderColor;
			}
		}

		&.dark {
			background-color: $tooltipDarkBackgroundColor;
			color: $tooltipDarkColor;
			box-shadow: $tooltipDarkBoxShadow;

			&::after {
				border-color: $tooltipDarkArrowAfterBorderColor;
			}
		}
	}
}