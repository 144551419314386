@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'src/styles/theme/index.scss';
// @import 'src/styles/font.css';

* {
	font-family: 'Inter', sans-serif;
	box-sizing: border-box;
}

ul {
	list-style-type: none;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}

a {
	color: #000;
	text-decoration: none;
}

h1 {
	margin-block-start: 0px;
	margin-block-end: 0px;
}

body {
	margin: 0px !important;
}
