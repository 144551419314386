$avatarImgWidth: var(--avatarImgWidth, 40px); 
$avatarImgHeight: var(--avatarImgHeight, 40px); 
$avatarTitleInfoPadding: var(--avatarTitleInfoPadding, 0px 0px 0px 10px); 

$avatarTitleFontSize: var(--avatarTitleFontSize, 14px); 
$avatarTitleMargin: var(--avatarTitleMargin, 0px 0px 14px 0px); 
$avatarTitlePadding: var(--avatarTitlePadding, 0px 5px 0px 0px); 
$avatarTitleFontSize: var(--avatarTitleFontSize, 14px); 
$avatarTitleColor: var(--avatarTitleColor, #6b6b6b); 

$avatarSubtitleFontSize: var(--avatarSubtitleFontSize, 12px); 
$avatarSubtitleColor: var(--avatarSubtitleColor, #6b6b6b); 