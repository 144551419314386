.article {
	display: flex;
	padding: 85px 0 0 0;
	flex-direction: column;
	width: 100%;
}

.padding {
	padding: 0 30px 45px 30px;
}
