.wrapper {
	padding: 24px 30px 48px 30px;
	display: flex;
	flex-direction: column;

	.title {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 38px;
	}
}
