.grid {
	display: flex;
	flex-direction: column;
    align-items: center;
    gap: 72px;

	.donutSize {
		width: 260px;
		position: relative;
	}
}
