@import 'src/styles/theme';

.selectItem {
	padding: 9px 12px 9px 12px !important;
	border-radius: 0px !important;
}

.wrapper {
	margin: 10px 0px 0px 0px;
	max-height: 120px;
}

.logoutWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	left: -17px;
	padding-left: 17px;
	height: 50px;
	position: relative;

	&:hover {
		background-color: $hoverLinksBg;
	}

	.icon {
		font-size: 18px;
		color: #344054;
	}

	.text {
		color: #344054;
		line-height: 20px;
		font-weight: 500;
		font-size: 14px;
	}
}
