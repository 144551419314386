$actionLinerHeight: var(--actionLinerHeight, 40px); 
$actionLinerNestedIndexLinerButtonPadding: var(--actionLinerNestedIndexLinerButtonPadding, 0px 10px 0px 5px); 
$actionLinerIndexLinerButtonPadding: var(--actionLinerIndexLinerButtonPadding, 0px 8px); 
$actionLinerIndexLinerButtonHoverBackgroundColor: var(--actionLinerIndexLinerButtonHoverBackgroundColor, #F3F4F6); 
$actionLinerActionLinerMargin: var(--actionLinerActionLinerMargin, 0 0 10px 0); 
$actionLinerSelectedLinerPadding: var(--actionLinerSelectedLinerPadding, 0 20px); 
$actionLinerSelectedActionsGap: var(--actionLinerSelectedActionsGap, 15px); 
$actionLinerAmountTitleFontSize: var(--actionLinerAmountTitleFontSize, 14px); 
$actionLinerIndexLinerPadding: var(--actionLinerIndexLinerPadding, 0 0 0 0); 
$actionLinerDynamicIndexActionsGap: var(--actionLinerDynamicIndexActionsGap, 20px); 
$actionLinerSelectedLinerButtonHoverBackgroundColor: var(--actionLinerSelectedLinerButtonHoverBackgroundColor, #4f46e5); 
$actionLinerSelectedLinerBackgroundColor: var(--actionLinerSelectedLinerBackgroundColor, #6366f1);
$actionLinerIndexLinerButtonBorderRadius: var(--actionLinerIndexLinerButtonBorderRadius, 4px); 
$actionLinerSelectedLinerBorderRadius: var(--actionLinerSelectedLinerBorderRadius, 4px); 
$actionLinerSelectedLinerColor: var(--actionLinerSelectedLinerColor, white); 