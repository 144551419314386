@import 'src/styles/theme';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin: 34px 0;
	align-self: flex-start;

	.tab {
		padding: 10px 14px;
		cursor: pointer;

		&:hover {
			background: $grayNeutral100;
		}

		&.active {
			background: #f9f5ff;
			border-radius: 6px;
			color: $primary700;

			&:hover {
				background-color: $primary100;
			}
		}
	}
}
