@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: center;

	.settingsBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		cursor: pointer;

		&:hover {
			background-color: $hoverLinksBg;
		}

		.settingsIcon {
			border-radius: 0;
			width: 50px;
			text-align: center;
			position: relative;
		}
	}

	.iconWrapper {
		svg {
			position: relative;
			top: 2px;
		}
	}
}
