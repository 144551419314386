@import '../theme.scss';

:root {
	--inputWrapperMargin: 0px;
	--inputBorder: 1px solid #{$borderElementsColor};
	--inputHeight: 44px;
	--inputBorderRadius: 8px;
	--inputBoxShadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	--inputFontSize: 16px;
	--inputFontWeight: 400;
	--inputLineHeight: 24px;
	--inputColor: #{$titleColor};
	--inputPlaceholderFocusLabelOpacity: 0;
	--inputFocusBorder: #{$formFocusBorder};
	--inputFocusBoxShadow: #{$formFocusBoxShadow};
	--inputPrefixColor: #475467;
	--inputPlaceholderFocusOpacity: 1;
}
