$toggleTitleFontSize: var(--toggleTitleFontSize, 14px); 
$toggleTitleMargin: var(--toggleTitleMargin, 0px 0px 5px 0px); 

$toggleSwitchWidth: var(--toggleSwitchWidth, calc(30px * 1.2)); 
$toggleSwitchHeight: var(--toggleSwitchHeight, calc(17px * 1.2)); 
$toggleSwitchMargin: var(--toggleSwitchMargin, 0px 10px 0px 10px); 

$toggleSliderBorderRadius: var(--toggleSliderBorderRadius, 30px); 
$toggleSliderCheckedBackgroundColor: var(--toggleSliderCheckedBackgroundColor, #6366f1); 
$toggleSliderFocusBoxShadow: var(--toggleSliderFocusBoxShadow, 0 0 1px #6366f1); 
$toggleSliderBackgroundColor: var(--toggleSliderBackgroundColor, #ccc); 

$toggleLabelColor: var(--toggleLabelColor, #7c7c7c); 
$toggleLabelFontSize: var(--toggleLabelFontSize, 15px); 
$toggleRelativeSize: 1.2;