$menuTopPosition: var(--menuTopPosition, calc(100% + 13px));
$menuBoxShadow: var(--menuBoxShadow, rgba(99, 99, 99, 0.2) 0px 2px 8px 0px);
$menuBackgroundColor: var(--menuBackgroundColor, white);
$menuPadding: var(--menuPadding, 8px 12px 10px 8px);
$menuBorderRadius: var(--menuBorderRadius, 6px);
$menuLineHeight: var(--menuLineHeight, 15px);
$menuFontWeight: var(--menuFontWeight, 500);
$menuFontSize: var(--menuFontSize, 12px);
$menuMinWidth: var(--menuMinWidth, 100px);
$menuMaxWidth: var(--menuMaxWidth, 550px);
$menuMaxHeight: var(--menuMaxHeight, 700px);

$menuAlignToRightFromRight: var(--menuAlignToRightFromRight, -10px);
$menuAlignToRightFromRightAfter: var(--menuAlignToRightFromRightAfter, 25px);

$menuAlignToLeftFromLeft: var(--menuAlignToLeftFromLeft, 0px);
$menuAlignToLeftFromLeftAfter: var(--menuAlignToLeftFromLeftAfter, 25px);

$menuUnstyledPadding: var(--menuUnstyledPadding, 0px);
