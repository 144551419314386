@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.signupContent {
	display: flex;
	align-items: right;
	justify-content: center;
	flex-direction: column;
	// height: 100%;
	width: 100%;
	max-width: 350px;
	position: relative;

	.title {
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 10px;
		width: 100%;
	}

	.subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: $gray600;
		margin-bottom: 25px;
		text-align: left;
	}

	.lineSeperator {
		width: 100%;
		text-align: center;
		border-bottom: 1px solid #667085;
		line-height: 0.1em;
		margin: 30px 0 30px;
		color: #667085;
		font-weight: 400;

		.lineText {
			background: #fff;
			padding: 0 10px;
			font-size: 13px;
		}
	}

	.formWrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;

		.inputWrapper {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.formInput {
				width: 100%;
			}

			.errorInput {
				margin: 5px 0px 10px 0px;
			}
		}

		.passwordWrapper {
			display: flex;
			flex-direction: column;
			gap: 6px;

			.passwordVisibilityIcon {
				font-size: 25px;
				color: $gray500;
				-webkit-user-select: none;
				/* Safari */
				-ms-user-select: none;
				/* IE 10 and IE 11 */
				user-select: none;
				/* Standard syntax */

				&:hover {
					background-color: transparent;
				}
			}

			.passwordErr {
				display: flex;
				gap: 8px;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
				color: $gray500;

				.checkIcon {
					color: #5fd788;
					font-size: 17px;
					font-weight: 700;
				}

				.xIcon {
					font-size: 17px;
					font-weight: 700;
					color: #e86062;
				}
			}
		}

		.inputWrapper,
		.passwordWrapper {
			.formText {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
				color: $gray700;
			}
		}

		.agreeTncWrapper {
			display: flex;
			font-weight: 500;
			font-size: 13px;
			line-height: 18px;
			gap: 8px;

			.termsAndConditionText {
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
				color: $gray700;

				.termsAndConditionTextLink {
					color: #5552ee;
					font-weight: 600;
				}
			}
		}

		.error {
			justify-content: flex-start !important;
			margin-top: -12px;
			padding-left: 5px;
			font-size: 13px;
		}

		.signInWrapper {
			display: flex;
			flex-direction: row;
			align-self: center;
			margin-top: 6px;
			gap: 5px;
			font-weight: 400;
			font-size: 13px;
			line-height: 18px;

			.signIn {
				color: $primary700;
				font-weight: 600;
			}

			.signIn:hover {
				cursor: pointer;
			}
		}
	}
}

@media (max-width: $largerDesktop) {
	.signupContent {
		max-width: 350px;

		.title {
			font-size: 30px;
			margin-bottom: 10px;
		}

		.subtitle {
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 25px;
		}

		.lineSeperator {
			line-height: 0.1em;
			margin: 23px 0 23px;

			.lineText {
				padding: 0 10px;
				font-size: 13px;
			}
		}

		.formWrapper {
			gap: 10px;

			.inputWrapper {
				gap: 4px;

				.formInput {
				}
			}

			.passwordWrapper {
				gap: 6px;

				.passwordVisibilityIcon {
					&:hover {
					}
				}

				.passwordErr {
					gap: 8px;
					font-size: 13px;
					line-height: 16px;

					.checkIcon {
						font-size: 17px;
					}

					.xIcon {
						font-size: 17px;
					}
				}
			}

			.inputWrapper,
			.passwordWrapper {
				.formText {
					font-size: 13px;
					line-height: 18px;
				}
			}

			.agreeTncWrapper {
				font-size: 13px;
				line-height: 18px;
				gap: 8px;

				.termsAndConditionText {
					font-size: 13px;
					line-height: 18px;

					.termsAndConditionTextLink {
					}
				}
			}

			.signInWrapper {
				margin-top: 6px;
				gap: 5px;
				font-weight: 400;
				font-size: 13px;
				line-height: 18px;

				.signIn {
				}

				.signIn:hover {
				}
			}
		}
	}
}
