@import './sidebar.default.scss';

.wrapper {
	padding: $controlledSidebarWrapperPadding;

	.logoWrapper {
		height: $controlledSidebarTogglerHeight;
		padding: $controlledSidebarTogglerPadding;
	}

	.fullList {
		.itemWrapper {
			.itemTitle {
				margin: $controlledSidebarFullListItemTitleMargin;
				white-space: nowrap;
			}
		}
	}

	.list {
		margin: $controlledListMargin;

		.itemWrapper {
			width: $controlledListItemWrapperWidth;
			height: $controlledListItemWrapperHeight;
			line-height: $controlledListItemWrapperLineHeight;
			font-size: $controlledListItemWrapperFontSize;
			font-weight: 500;
			padding: $controlledListItemWrapperPadding;
			cursor: pointer;
			margin: $controlledListItemWrapperMargin;
			display: flex;
			align-items: center;
			// border-radius: 0px;

			&:hover {
				background-color: $controlledListItemWrapperHoverBackgroundColor;
			}

			&.activeItem {
				background-color: $controlledListItemWrapperActiveItemBackgroundColor;
				// border-radius: 0px;

				&:hover {
					background-color: $controlledListItemWrapperActiveItemHoverBackgroundColor;
				}

				.itemLink {
					color: $primary700;
				}
			}

			.itemLink {
				height: 100%;
				width: 100%;

				.item {
					display: flex;
					align-items: center;
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.miniLogo {
	position: relative;
	width: 50px;
	height: 18px;
}
