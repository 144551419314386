@import './chart-legend.default.scss';

@keyframes opacityIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.menuMinWidth {
	min-width: 256px !important;
}

.legend {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: $chartLegendLegendGap;
	padding: $chartLegendLegendPadding;
	position: relative;
	top: $chartLegendLegendFromTop;
	animation: opacityIn 0.2s ease-in;

	.itemWrapper {
		min-width: $chartLegendItemWrapper;
		margin-right: $chartLegendItemWrapperMargin;

		.item {
			display: flex;
			align-items: center;
			flex-direction: column;

			.marker {
				margin: $chartLegendMarkerMargin;
				height: $chartLegendMarkerHeight;
				width: 100%;

				&.hide {
					visibility: hidden;
				}
			}

			.content {
				display: flex;
				height: $chartLegendContentHeight;
				width: 100%;

				.labelWrapper {
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
					padding: $chartLegendLabelWrapperPadding;
					width: 100%;
					cursor: pointer;

					&:hover {
						background-color: $chartLegendLabelWrapperHoverBackgroundColor;
					}

					.label {
						font-size: $chartLegendLabelFontSize;
						color: $chartLegendLabelColor;
						margin: $chartLegendLabelMargin;
					}

					.sum {
						font-size: $chartLegendSumFontSize;
						text-align: left;
						width: 100%;
						color: black;
						line-height: $chartLegendSumLineHeight;
						padding: $chartLegendSumPadding;
					}

					.skeletonSum {
						width: 100%;
					}
				}

				.arrow {
					align-items: baseline;
					font-size: 20px;
					padding: $chartLegendArrowPadding;
				}
			}
		}
	}
}
