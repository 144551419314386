.active {
	font-size: 20px;
	position: relative;
	left: -2px;
}

.paused {
	font-size: 15px;
}

.draft {
	font-size: 15px;
}

.deleted {
	font-size: 14px;
}

.countries {
	display: flex;
	align-items: center;
	gap: 7px;

	img {
		width: 18px;
		position: relative;
		top: -1px;
	}

	span {
		line-height: 18px;
	}
}
