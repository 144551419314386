@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: center;

	.notificationBell {
		border-radius: 0;
		width: 50px;
		height: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
		position: relative;
		left: 1px;

		&:hover {
			background-color: $hoverLinksBg;
		}
	}

	.notificationsMenu {
		min-width: 350px;
		padding: 10px 14px;

		.title {
			text-align: left;
			font-size: 18px;
			font-weight: bold;
			color: $titleColor;
		}

		.noting {
			text-align: center;
			font-size: 12px;
		}

		.avatar {
			display: flex;
			align-items: center;

			> img {
				width: 40px;
				height: 40px;
			}

			.info {
				text-align: left;
				padding-left: 10px;

				.fullname {
					font-size: 14px;
					margin-bottom: 5px;
					color: $titleColor;
				}

				.email {
					font-size: 12px;
					color: $titleColor;
				}
			}
		}
	}
}
