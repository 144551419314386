@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import '../../../../styles/theme';

.itemWrapper {
	display: flex;
	align-items: top;
	gap: 12px;
	position: relative;
	margin-left: 30px;

	&.active {
		.title,
		.desc {
			color: $primaryColor !important;
		}
	}

	.line {
		position: absolute;
		height: 100%;
		left: 10px;
		width: 2px;
		background-color: $gray200;
		border-radius: 2px;
		z-index: 1;
	}

	.circle {
		display: flex;
		z-index: 2;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: $primary50;
		box-shadow: 0px 0px 0px 4px $primary100;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $primaryColor;
		}
	}

	.info {
		margin: 0px 0px 24px 10px;
		width: 200px;

		.title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #344054;

			&:hover {
				font-weight: 700;
			}
		}

		&:hover {
			font-weight: 700;
		}
	}

	.desc {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #475467;
	}
}

.subWrapper {
	display: flex;
	align-items: center;

	&:not(&.disabled) {
		&:hover {
			span {
				font-weight: 500;
			}
		}
	}

	&.error {
		margin-left: -10px;

		.label {
			color: $errorColor;
		}
	}

	&.disabled {
		// pointer-events: none;
		// cursor: not-allowed;
		opacity: 0.5;
	}

	.errorCircle {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: $errorColor;
		margin-right: 5px;
	}

	.label {
		color: #475467;
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;

		&.active {
			color: $primaryColor !important;
		}
	}

	.errorIcon {
		margin-left: 4px;
		animation: opacityIn 0.15s ease-in;
		font-size: 16px;
		color: $errorColor;
	}
}
