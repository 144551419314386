@import './network-error-modal.default.scss';

.wrapper {
	padding: $networkErrorModalWrapperPadding;

	.title {
		font-size: $networkErrorModalTitleFontSize;
		font-weight: $networkErrorModalTitleFontWeight;
		margin: $networkErrorModalTitleMargin;
	}

	.subtitle {
		font-size: $networkErrorModalSubtitleFontSize;
		font-weight: $networkErrorModalSubtitleFontWeight;
		margin-bottom: $networkErrorModalSubtitleMargin;
	}

	.list {
		font-size: $networkErrorModalListFontSize;

		> li {
			list-style: $networkErrorModalListStyle;
		}
	}
}
