@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: center;
}

.breadcrumbWrapper {
	display: flex;
	align-items: center;
	position: relative;

	.skeletons {
		width: 140px;
		height: 50px;

		.topSkeleton {
			width: 75px;
		}

		.bottomSkeleton {
			width: 150px;
		}
	}

	.next {
		font-size: 25px;
		font-weight: 100;
		margin: 0px 15px;
		color: #dadce0;
		position: absolute;
		right: 0;
	}
}

.active {
	color: $primary600;
}

.emptyState {
	width: 300px;
	top: calc(100% + 5px) !important;
}
