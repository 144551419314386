@import './toggle-menu.default';

.wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.togglerWrapper {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}