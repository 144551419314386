@import '../table.default.scss';

$searchFilterSearchIconPadding: var(--searchFilterSearchIconPadding, 0 0 0 10px); 
$searchFilterInputFontSize: var(--searchFilterInputFontSize, 13px); 
$searchFilterInputColor: var(--searchFilterInputColor, #636363); 
$searchFilterInputPadding: var(--searchFilterInputPadding, 0 0 0 10px); 
$searchFilterInputBorder: var(--searchFilterInputBorder, 1px solid $tableBorderColor); 
$searchFilterInputBackgroundColor: var(--searchFilterInputBackgroundColor, white); 
$searchFilterInputWidth: var(--searchFilterInputWidth, 175px); 
$searchFilterInputPadding: var(--searchFilterInputPadding, 3px 8px 3px 30px); 
$searchFilterInputHoverBorder: var(--searchFilterInputHoverBorder, 1px solid $tableBorderColor); 
$searchFilterInputBorderRadius: var(--searchFilterInputBorderRadius, 8px); 