@import '../theme.scss';

:root {
	--dropdownWrapperMargin: 0px;
	--dropdownActiveLabelOpacity: 0;
	--dropdownActiveBorder: 1px solid #d6bbfb;
	--dropdownActiveBoxShadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
	--dropdownWrapperMaxHeight: 44px;
	--dropdownWrapperColor: #344054;
	--dropdownOptionsFromTop: 50px;
	--dropdownOptionHighlightBackgroundColor: #f9fafb;
	--dropdownFocusBorder: #{$formFocusBorder};
	--dropdownFocusBoxShadow: #{$formFocusBoxShadow};
	--dropdownOptionsMaxHeight: 300px;
	--dropdownDisabledOpacity: 1;
	--dropdownDropdownBarDisabledColor: #{$gray300};
	--dropdownArrowIcon: #{$gray300};
	--dropdownWrapperDisabledBackgroundColor: white;
	--dropdownWrapperDisabledBorder: 1px solid #{$gray300};
	--dropdownOptionActiveDisabledColor: #{$gray300};
	--dropdownOptionActiveArrowDisabledColor: #{$gray300};
	--dropdownOptionsBorderRadius: 8px;
}
