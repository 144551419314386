@import '@monorepo/base/src/styles/text/overflow';
@import '@monorepo/base/src/styles/devices';

.wrapper {
	display: flex;
	flex-wrap: wrap;
	column-gap: 100px;
	row-gap: 16px;
	width: 100%;
	padding: 0 58px;

	@media (max-width: 1600px) {
		column-gap: 10px;
	}

	.legend {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 150px;

		.tooltipWrapper {
			.title {
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: #84818a;
				@include text-overflow;
				max-width: 150px;
			}
		}
		.valueWrapper {
			display: flex;
			align-items: center;
			gap: 8px;

			.value {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				color: #2e2c34;

				&.skeleton {
					width: 150px;
				}
			}

			.color {
				width: 7px;
				height: 7px;
				border-radius: 50%;
			}
		}
	}
}
