.wrapper {
    padding: 10px;
    text-align: center;

    .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 15px;
        white-space: normal;
    }

    .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin: 0 auto 20px auto;
        max-width: 350px;
        display: flex;
        justify-content: center;
    }

    .image {
        width: 65%;
        margin-bottom: 5px;
        width: 200px;
        height: 164px;
    }

    .icon {
        display: flex;
        justify-content: center;
    }

    .actions {
        padding-bottom: 5px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}