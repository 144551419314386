@import 'src/styles/theme.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;

	.inputWrapper {
		width: 100%;
		max-width: 400px;
	}
}
