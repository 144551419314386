@import 'src/styles/theme';

.gbutton {
	width: 194px;
	height: 194px;
	border: 1px solid $borderElementsColor;

	&.mini {
		width: 110px;
		height: 110px;

		.radio {
			top: 8px;
			left: 8px;
		}

		.title {
			margin-bottom: 0px !important;
		}

		.icon {
			font-size: 30px;
			top: 45%;
		}
	}
}

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;

	.radio {
		position: absolute;
		top: 13px;
		left: 13px;
	}

	.badge {
		position: absolute;
		top: 11px;
		right: 11px;
	}

	.info {
		position: absolute;
		bottom: 16px;
		width: 100%;
		text-align: center;

		.title {
			color: #000000;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 8px;

			&.disabled {
				color: #d0d5dd;
			}
		}

		.description {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			text-align: center;
			color: $subTitleColor;
		}
	}

	.icon {
		font-size: 50px;
		color: #000000;
		position: absolute;
		top: 35%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.disabled {
		color: #d0d5dd;
		cursor: not-allowed;
	}
}
