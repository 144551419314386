@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;
	gap: 122px;

	.contentWrapper {
		display: flex;
		flex-direction: column;
		width: 706px;
		align-self: center;

		.imageWrapper {
			display: flex;
			justify-content: center;
			width: 100%;

			.img {
				width: 162px;
			}
		}

		.heading {
			margin-top: 43px;
			font-weight: 600;
			font-size: 28px;
			line-height: 38px;
			color: $gray900;
			text-align: center;
		}

		.text {
			margin-top: 16px;
			font-weight: 400;
			font-size: 16px;
			line-height: 28px;
			color: $gray600;
		}

		.actions {
			margin-top: 50px;
			display: flex;
			justify-content: center;
			gap: 24px;

			.btn {
				width: 172px;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}
