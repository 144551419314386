$inputItemTitleDescMargin: var(--inputItemTitleDescMargin, 0px);

$inputTitleColor: var(--inputTitleColor, #344054);
$inputTitleFontWeight: var(--inputTitleFontWeight, 500);
$inputTitleFontSize: var(--inputTitleFontSize, 14px);
$inputTitleLineHeight: var(--inputTitleLineHeight, 20px);

$inputDescColor: var(--inputDescColor, #475467);
$inputDescFontWeight: var(--inputDescFontWeight, 400);
$inputDescFontSize: var(--inputDescFontSize, 14px);
$inputDescLineHeight: var(--inputDescLineHeight, 20px);