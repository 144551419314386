@import 'src/styles/theme';

.actionsMenu {
	padding: 2px 0px 0px 0px;
	width: 200px;

	.actionsTitle {
		font-size: 14px;
		font-weight: 500;
		color: $secondaryColor;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		padding: 10px;
	}
}

.toggler {
	display: flex;
	align-items: center;
	color: $secondaryColor;

	&:hover {
		background-color: $hoverGrayColor;
	}
}
