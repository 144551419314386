@import './action-modal.default.scss';

.content {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;

	.iconWrapper {
		padding: $actionModalContentIconWrapperPadding;

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin: 0 auto;
			width: $actionModalContentIconWidth;
			height: $actionModalContentIconHeight;

			&.warning {
				background-color: $actionModalContentIconWarningBackgroundColor;
				color: $actionModalContentIconWarningColor;
			}

			&.error {
				background-color: $actionModalContentIconErrorBackgroundColor;
				color: $actionModalContentIconErrorColor;
			}

			&.info {
				background-color: $actionModalContentIconInfoBackgroundColor;
				color: $actionModalContentIconInfoColor;
			}
		}
	}

	.childrenWrapper {
		flex-grow: 2;
	}
}

.actions {
	display: flex;
	border-radius: $actionModalActionsBorderRadius;
	padding: $actionModalActionsPadding;
	background-color: $actionModalActionsBackgroundColor;
	width: 100%;
	gap: $actionModalActionsGap;
	justify-content: flex-end;

	.cancelBtn {
		border: $actionModalActionsCancelBtnBorder;
		color: $actionModalActionsCancelBtnColor;
		width: $actionModalActionsCancelBtnWidth;

		&:hover {
			color: $actionModalActionsCancelBtnColorHover;
		}
	}

	.actionBtn {
		width: $actionModalActionsActionBtnWidth;
	}

	.warning {
		background-color: $actionModalActionsWarningBackgroundColor;
		border: $actionModalActionsWarningBorder;

		&:hover {
			background-color: $actionModalActionsWarningHoverBackgroundColor;
		}
	}

	.error {
		background-color: $actionModalActionsErrorBackgroundColor;
		border: $actionModalActionsErrorBorder;

		&:hover {
			background-color: $actionModalActionsErrorHoverBackgroundColor;
		}
	}
}
