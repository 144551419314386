@import './radio.default.scss';

.wrapper {
    display: flex;
    align-items: baseline;

    .circle {
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        height: 16px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: $radioCircleBorder;

        &.active {
            border: $radioCircleActiveBorder;


            .innerCircle {
                background-color: $radioActiveInnerCircleBackgroundColor;
            }
        }

        &.multi {
            border-radius: 4px;

            .innerCircle {
                visibility: hidden;
            }
        }

        &.disabled {
            border: $radioCircleBorderDisabled;
            color: $radioCircleColorDisabled;
			cursor: not-allowed;

            // background-color: red;
        }

        .innerCircle {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: transparent;
        }
    }

    .children {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}