@import '../theme.scss';

:root {
	--textButtonFontSize: 14px;
	--textButtonFontWeight: 400;
	--textButtonLineHeight: 28px;
	--primaryTextButtonColor: #{$primaryColor};
	--secondaryTextButtonColor: #{$titleColor};
	--secondayTextHoverBackgroundColor: #{$hoverGrayColor};

	--primaryButtonBorderRadius: 8px;
	--primaryButtonFontSize: 14px;
	--primaryButtonFontWeight: 600;
	--primaryButtonColor: white;
	--primaryButtonPadding: 6px 14px;
	--primaryButtonHoverBackgroundColor: #{$primaryColorDarken};

	--secondaryButtonBorderRadius: 8px;
	--secondaryButtonBackgroundColor: white;
	--secondaryButtonHoverBackgroundColor: #f9fafb;
	--secondaryButtonHoverColor: #344054;
	--secondaryButtonColor: #344054;
	--secondaryButtonFontWeight: 600;
	--secondaryButtonBorder: 1px solid #d0d5dd;
	--secondaryButtonPadding: 6px 14px;
	--secondaryButtonHeight: 40px;
}
