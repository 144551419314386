@import './sidebar.default.scss';
@import '@monorepo/base/src/styles/devices';

@mixin widthInAnimation($openWidth, $closeWidth) {
	@keyframes widthIn {
		0% {
			width: $closeWidth;
		}

		100% {
			width: $openWidth;
		}
	}
}

@mixin widthOutAnimation($openWidth, $closeWidth) {
	@keyframes widthOut {
		0% {
			width: $openWidth;
		}

		100% {
			width: $closeWidth;
		}
	}
}

.wrapper {
	height: 100%;
	width: 100%;
	max-width: $openWidth;
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: $sidebarPrimary;
	border-right: 1px solid #e7eaf0;
	z-index: 9;

	&.open {
		&.animate {
			@media (max-width: $tablet - 1) {
				@include widthInAnimation($openWidth, $mobileCloseWidth);
				box-shadow: (0 0 0 / 10%) 0px 4px 12px;
				animation: widthIn 0.25s ease-out forwards;
			}

			@media (min-width: $tablet) {
				@include widthInAnimation($openWidth, $bigResCloseWidth);
				box-shadow: 0 0 2rem 0 rgba(209, 209, 209, 0.15);
				animation: widthIn 0.4s ease-out forwards;
			}
		}

		&.static {
			width: $openWidth;
		}
	}

	&.close {
		&.animate {
			@media (max-width: $tablet - 1) {
				@include widthOutAnimation($openWidth, $mobileCloseWidth);
				transform: translateX(-200px);
			}

			@media (min-width: $tablet) {
				@include widthOutAnimation($openWidth, $bigResCloseWidth);
			}

			&.triggerAnimation {
				animation: widthOut 0.2s ease-out forwards;
			}

			&.noAnmiation {
				width: $bigResCloseWidth;
			}
		}

		&.static {
			@media (max-width: $tablet - 1) {
				transform: translateX(-200px);
				width: $mobileCloseWidth;
			}

			@media (min-width: $tablet) {
				width: $bigResCloseWidth;
			}
		}
	}

	&.lock {
		&.animate {
			@media (min-width: $tablet) {
				@include widthInAnimation($openWidth, $bigResCloseWidth);
				box-shadow: 0 0 2rem 0 rgba(209, 209, 209, 0.15);
				animation: widthIn 0.2s ease-out forwards;
			}
		}

		&.static {
			width: $openWidth;
		}
	}
}
