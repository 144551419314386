$controlledDaterLabelFontSize: var(--controlledDaterLabelFontSize, 14px); 
$controlledDaterLabelFontWeight: var(--controlledDaterLabelFontWeight, 400); 
$controlledDaterLabelColor: var(--controlledDaterLabelColor, #6b6b6b); 
$controlledDaterBtnMargin: var(--controlledDaterBtnMargin, 0 0 0 7px); 
$controlledDaterBtnBorderColor: var(--controlledDaterBtnBorderColor, #6366f1); 
$controlledDaterBtnColor: var(--controlledDaterBtnColor, #6b6b6b); 
$controlledDaterBtnHoverColor: var(--controlledDaterBtnHoverColor, #e7e7e7); 
$controlledDaterActionsBorderTop: var(--controlledDaterActionsBorderTop, solid 1px #eff2f7); 
$controlledDaterActionsPadding: var(--controlledDaterActionsPadding, 10px 10px 0 0); 
$controlledDaterActionsFontSize: var(--controlledDaterActionsFontSize, 14px);
$controlledDaterIconColor: var(--controlledDaterIconColor, #6b6b6b);
$controlledDaterCancelBtnHoverBackgroundColor: var(--controlledDaterCancelBtnHoverBackgroundColor, none);
$controlledDaterCancelBtnHoverBorderRadius: var(--controlledDaterCancelBtnHoverBorderRadius, 4px);