@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: right;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	max-width: 350px;
	position: relative;

	.title {
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 10px;
		width: 100%;
	}

	.subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: $gray600;
		margin-bottom: 25px;
		text-align: left;
	}

	.formWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;

		.inputWrapper {
			display: flex;
			gap: 4px;
			flex-direction: column;

			.formText {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 20px;
				color: $gray700;
			}

			.formInput {
				width: 100%;

				.passwordVisibilityIcon {
					-webkit-user-select: none; /* Safari */
					-ms-user-select: none; /* IE 10 and IE 11 */
					user-select: none; /* Standard syntax */

					&:hover {
						background-color: transparent;
					}
				}
			}
		}

		.letsGoBtn {
			margin-bottom: 8px;
		}

		.switchUserWrapper {
			text-align: center;
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;

			.email {
				font-weight: 600;
				color: $gray700;
			}

			.switchUser {
				color: $primary700;
				font-weight: 600;
			}

			.switchUser:hover {
				cursor: pointer;
			}
		}
	}
}
