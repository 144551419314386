$inputWrapperMargin: var(--inputWrapperMargin, 25px 0px 0px 0px);
$inputWrapperMaxHeight: var(--inputWrapperMaxHeight, 900px);

$inputLabelTop: var(--inputLabelTop, -12px);
$inputLabelLeft: var(--inputLabelLeft, 10px);
$inputLabelFontSize: var(--inputLabelFontSize, 12px);
$inputLabelBackgroundColor: var(--inputLabelBackgroundColor, white);
$inputLabelPadding: var(--inputLabelPadding, 0 6px);
$inputLabelColor: var(--inputLabelColor, #7c7c7c);
$inputLabelOpacity: var(--inputLabelOpacity, 0);

$inputWrapperIconFromLeft: var(--inputWrapperIconFromLeft, 12px);
$inputWrapperIconColor: var(--inputWrapperIconColor, #667085);
$inputWithIconPadding: var(--inputWithIconPadding, 0px 0px 0px 42px);
$inputWrapperMaxWidth: var(--inputWrapperMaxWidth, auto);

$inputPrefixColor: var(--inputPrefixColor, #475467);

$inputMaxWidth: var(--inputMaxWidth, 400px);
$inputMinWidth: var(--inputMinWidth, auto);
$inputHeight: var(--inputHeight, 40px);
$inputBoxShadow: var(--inputBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05));
$inputColor: var(--inputColor, #383838);
$inputBorder: var(--inputBorder, 1px solid #d0d5dd);
$inputBorderRadius: var(--inputBorderRadius, 8px);
$inputPadding: var(--inputPadding, 16px);
$inputFontSize: var(--inputFontSize, 16px);
$inputFontWeight: var(--inputFontWeight, normal);
$inputLineHeight: var(--inputLineHeight, normal);

$inputPlaceholderFontSize: var(--inputPlaceholderFontSize, 14px);
$inputPlaceholderFocusOpacity: var(--inputPlaceholderFocusOpacity, 0);
$inputPlaceholderFocusLabelOpacity: var(--inputPlaceholderFocusLabelOpacity, 1);

$inputErrorBorder: var(--inputErrorBorder, 1px solid #fb6340);
$inputErrorTouched: var(--inputErrorTouched, 1px solid #2dce89);
$inputExtraMaxWidth: var(--inputExtraMaxWidth, 800px);

$inputFocusBorder: var(--inputFocusBorder, #{$inputBorder});
$inputFocusBoxShadow: var(--inputFocusBoxShadow, #{$inputBoxShadow});