@import 'src/styles/theme';
@import './columns-picker.default.scss';
@import '@monorepo/base/src/styles/keyframes/slide-in-up';

.wrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;

	.columnWrapper {
		display: flex;
		align-items: baseline;
	}

	.columnPickerMenu {
		padding: $columnPickerColumnPickerMenuPadding;
		text-align: left;

		.subtitle {
			font-size: $columnPickerSubtitleFontSize;
			color: $titleColor;
			margin: $columnPickerSubtitleMargin;
		}

		.list {
			width: $columnPickerListWidth;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin: $columnPickerListMargin;

			.item {
				display: flex;
				width: 48%;
				line-height: $columnPickerItemLineHeight;
				border-radius: $columnPickerItemBorderRadius;
				padding: $columnPickerItemPadding;

				&:hover {
					background-color: $columnPickerBackgroundColorHover;
				}

				.content {
					height: 100%;
					display: flex;
					align-items: center;
					gap: $columnPickerContentGap;

					.checkbox {
						width: $columnPickerCheckboxWidth;
						height: $columnPickerCheckboxHeight;
						margin-right: $columnPickerCheckboxMargin;
						accent-color: $columnPickerCheckboxAccentColor;
					}

					.title {
						font-size: $columnPickerTitleFontSize;
						font-weight: $columnPickerTitleFontWeight;
					}
				}
			}
		}

		.actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: $columnPickerActionsGap;
			padding: $columnPickerActionsPadding;
			margin: $columnPickerActionsMarginTop;
		}
	}
}