@import '../theme.scss';

:root {
	--pageBarTitleColor: #{$titleColor};
	--pageBarTitleFontSize: 30px;
	--pageBarTitleFontWeight: 600;
	// --pageBarPadding: 0 0 35px 0;
	// --pageBarBorderBottom: 1px solid #F2F4F7;
	// --pageBarMargin: 15px 15px 15px 15px;
}
