@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.loginButtonsWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 100%;
}

.loginButtonLink {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	border-color: #d0d5dd;
	background-color: transparent;
	background: #ffffff;
	border: 1px solid #d0d5dd;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #344054;
	gap: 10px;
	justify-content: flex-start;
	padding-left: 72px;
}

.loginButtonLink:hover {
	background: #f9fafb;
}

.loginButtonLink:focus {
	background: #ffffff;
	border: 1px solid #d0d5dd;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
}

.icon {
	width: 25px;
}

@media (max-width: $largerDesktop) {
	.loginButtonsWrapper {
		gap: 10px;
	}

	.loginButtonLink {
		font-size: 14px;
		line-height: 20px;
		gap: 10px;
		padding-left: 72px;
	}

	.loginButtonLink:hover {
	}

	.loginButtonLink:focus {
	}

	.icon {
		width: 18px;
	}
}
