@import './selection-column.default.scss';

.selectionWithStatusColumn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .checkbox {
        margin: $selectionColumnCheckboxMargin;
        accent-color: $selectionColumnCheckboxAccentColor;
    }
}

.selectionColumn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .checkbox {
        margin: $selectionColumnCheckboxNestedMargin;
    }
}

.checkbox {
    &[type=checkbox] {
        -moz-appearance:none;
        -webkit-appearance:none;
        -o-appearance:none;
        outline: none;
        content: none;	
        cursor: pointer;
    }
    
    &[type=checkbox]:before {
        font-family: "outlined-icons";
        content: "\f1e7";
        color: transparent !important;
        background: #fff;
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid #D0D5DD;
        border-radius: 6px;
    }

    &[type=checkbox]:hover:before {
        border: 1px solid #7F56D9;
    }
    
    &[type=checkbox]:checked:before {
        font-size: 15px;
        color: #7F56D9 !important;
        background: #F9F5FF;
        border: 1px solid #7F56D9;
    }
    &[type=checkbox]:checked:hover:before {
        border: 1px solid #5b22d6;
    }
}