@import './input-item.default.scss';

.inputItem {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 700px;

    &.disableFlex {
        display: block;
    }

    &.max {
        max-width: 100%;
    }

    .inputTitle {
        +.inputDesc {
            margin-top: $inputItemTitleDescMargin;
        }
    }
}

.inputInfo {
    margin-right: 10px;
    width: 290px;
    min-width: 290px;
    max-width: 290px;
}

.inputTitle {
    color: $inputTitleColor;
    font-weight: $inputTitleFontWeight;
    font-size: $inputTitleFontSize;
    line-height: $inputTitleLineHeight;
}

.inputDesc {
    color: $inputDescColor;
    font-weight: $inputDescFontWeight;
    font-size: $inputDescFontSize;
    line-height: $inputDescLineHeight;
}