@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	padding: 32px;
	display: flex;
	flex-direction: column;

	.imageWrapper {
		display: flex;
		justify-content: center;
		width: 100%;

		.img {
			width: 222px;
		}
	}

	.heading {
		margin-top: 43px;
		margin-bottom: 15px;
		font-weight: 600;
		font-size: 28px;
		line-height: 38px;
		color: $gray900;
		text-align: center;
	}

	.text {
		margin: 0 auto;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
		color: $gray600;
		text-align: center;
	}

	.actions {
		margin-top: 50px;
		display: flex;
		justify-content: center;
		gap: 24px;

		.btn {
			width: 172px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}
	}
}
